import { useAsyncCallback } from '@orus.eu/pharaoh'
import { trpc } from '../../client'
import { sessionManager } from '../session'
import { useNavigateTo } from './use-navigate-to-route'

export function useDisconnect(): () => void {
  const navigateToLoginPage = useNavigateTo({ to: '/login' })
  return useAsyncCallback(async () => {
    await trpc.sessions.logout.mutate()
    await sessionManager.refreshSession()
    navigateToLoginPage()
  }, [navigateToLoginPage])
}
